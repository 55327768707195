var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '700px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop-title"
  }, [_vm._v(_vm._s(_vm.$lang("Mine Task")))]), _c('div', {
    staticClass: "taskbox"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("描述")]), _c('div', {
    staticClass: "input"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.description,
      expression: "modifyData.description"
    }],
    domProps: {
      "value": _vm.modifyData.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "description", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("幸运挖矿任务ID")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.lucky_task_id,
      expression: "modifyData.lucky_task_id"
    }],
    domProps: {
      "value": _vm.modifyData.lucky_task_id
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "lucky_task_id", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("幸运奖金（金币数量）")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.lucky_coin,
      expression: "modifyData.lucky_coin"
    }],
    domProps: {
      "value": _vm.modifyData.lucky_coin
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "lucky_coin", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("开始时间(UTC)")]), _c('div', {
    staticClass: "input"
  }, [_c('m-date-picker', {
    attrs: {
      "value": _vm.modifyData.start_time_format
    },
    on: {
      "inputFn": _vm.changeStartTime
    }
  })], 1)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("结束时间(UTC)")]), _c('div', {
    staticClass: "input"
  }, [_c('m-date-picker', {
    attrs: {
      "value": _vm.modifyData.end_time_format
    },
    on: {
      "inputFn": _vm.changeEndTime
    }
  })], 1)]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("状态")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.status,
      expression: "modifyData.status"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("启用")]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("禁用")])])])])])]), _c('div', {
    staticClass: "btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };