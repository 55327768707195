import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popPrompt",
  props: {
    title: {
      type: String,
      default: ""
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    confirmText: {
      type: String,
      default: "Confirm"
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {};
  },
  methods: {
    cancelFn() {
      this.$emit("cancel");
    },
    confirmFn() {
      this.$emit("confirm");
    }
  }
};