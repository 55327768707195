var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("创建新抽奖")]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)]), _c('div', {
    staticClass: "tabs"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 0 && 'active',
    on: {
      "click": function ($event) {
        _vm.selectTab = 0;
        _vm.onRefresh();
      }
    }
  }, [_vm._v("启用的")]), _c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 1 && 'active',
    on: {
      "click": function ($event) {
        _vm.selectTab = 1;
        _vm.onRefresh();
      }
    }
  }, [_vm._v("禁用的")])]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.provider) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s type"
    }, [_vm._v(" " + _vm._s(item.type == 0 ? '大转盘' : '') + " ")]), _c('div', {
      staticClass: "s reward"
    }, [_vm._v(" " + _vm._s(item.price_coin) + " ")]), _c('div', {
      staticClass: "s reward"
    }, [_vm._v(" " + _vm._s(item.price_stock) + " ")]), _c('div', {
      staticClass: "s status",
      class: `status_${item.status}`
    }, [_vm._v(" " + _vm._s(item.status == 0 ? '禁用' : '启用') + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(_vm.cutStr(item.created_at)) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onReward(item.id);
        }
      }
    }, [_vm._v("奖项")]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onLogs(item.id);
        }
      }
    }, [_vm._v("获奖记录")]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")])])]);
  })], 2), _c('pop-luckylottery', {
    attrs: {
      "show": _vm.popTaskShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popTaskShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s label"
  }, [_vm._v(" 服务商代号 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 标题 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" ICON ")]), _c('div', {
    staticClass: "s type"
  }, [_vm._v(" 类型 ")]), _c('div', {
    staticClass: "s reward"
  }, [_vm._v(" 消耗金币 ")]), _c('div', {
    staticClass: "s reward"
  }, [_vm._v(" 消耗KEY ")]), _c('div', {
    staticClass: "s status"
  }, [_vm._v(" 状态 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 添加时间 ")]), _c('div', {
    staticClass: "s opt"
  })]);
}];
export { render, staticRenderFns };