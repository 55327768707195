var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "stat-main"
  }, [_c('h2', [_vm._v("用户")]), _c('div', {
    staticClass: "stat-list"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("总用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.user_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(0);
      }
    }
  }, [_vm.stepLoading[0] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])])]), _c('div', {
    staticClass: "stat-list"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("今日新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.today_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(1);
      }
    }
  }, [_vm.stepLoading[1] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("昨日新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.yestoday_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(2);
      }
    }
  }, [_vm.stepLoading[2] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("3日新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.three_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(3);
      }
    }
  }, [_vm.stepLoading[3] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("7日新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.week_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(4);
      }
    }
  }, [_vm.stepLoading[4] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("1个月新增用户数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.month_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(5);
      }
    }
  }, [_vm.stepLoading[5] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])])]), _c('div', {
    staticClass: "stat-list"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("今日登录用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.today_login || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(6);
      }
    }
  }, [_vm.stepLoading[6] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("昨日登录用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.yestoday_login || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(7);
      }
    }
  }, [_vm.stepLoading[7] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("3日登录用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.three_login || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(8);
      }
    }
  }, [_vm.stepLoading[8] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("7日登录用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.week_login || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepData(9);
      }
    }
  }, [_vm.stepLoading[9] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        !_vm.isLoading && _vm.onRefresh();
      }
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)]), _c('h2', [_vm._v("钥匙")]), _c('div', {
    staticClass: "stat-list"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("总钥匙数")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.stock_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepKeyData(0);
      }
    }
  }, [_vm.stepKeyLoading[0] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("有钥匙的用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.stock_user_total || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepKeyData(1);
      }
    }
  }, [_vm.stepKeyLoading[1] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])])]), _c('div', {
    staticClass: "stat-list"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("今日新增钥匙量")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.today_new_stock || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepKeyData(2);
      }
    }
  }, [_vm.stepKeyLoading[2] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("今日获取钥匙的用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.today_new_stock_user || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepKeyData(3);
      }
    }
  }, [_vm.stepKeyLoading[3] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("昨日新增钥匙量")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.yesterday_new_stock || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepKeyData(4);
      }
    }
  }, [_vm.stepKeyLoading[4] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('div', {
    staticClass: "stat-item-tit"
  }, [_vm._v("昨日获取钥匙的用户")]), _c('div', {
    staticClass: "stat-item-val"
  }, [_vm._v(_vm._s(_vm.statData.yesterday_new_stock_user || 0))]), _c('div', {
    staticClass: "stat-item-opt"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.getStepKeyData(5);
      }
    }
  }, [_vm.stepKeyLoading[5] ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        !_vm.isKeyLoading && _vm.onKeyRefresh();
      }
    }
  }, [_vm.isKeyLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };