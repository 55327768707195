var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "main"
  }, [_vm.isRouterAlive ? [_c('layout')] : _vm._e()], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };