var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCheck
    }
  }, [_vm.isChecking ? [_vm._v("检查中...")] : [_vm._v("检查")]], 2)]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.symbol) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.balance) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onDeposit(item);
        }
      }
    }, [_vm._v("充值")])])]);
  })], 2), _c('pop-deposit', {
    attrs: {
      "show": _vm.popDepositShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popDepositShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s label"
  }, [_vm._v(" 代币 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 余额 ")]), _c('div', {
    staticClass: "s opt"
  })]);
}];
export { render, staticRenderFns };