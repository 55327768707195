import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import noZoom from "./assets/js/noZoom";
import cookie from "js-cookie";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload,
      oldPath: null,
      newPath: null
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "isSign"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isLoading: false,
      isRouterAlive: true
    };
  },
  watch: {
    "$route.path": {
      handler(newPath, oldPath) {
        this.doCheckLogin();
      },
      deep: true
    },
    isSign: {
      handler(newBool, oldBool) {
        if (!newBool && this.$route.path !== '/login') {
          this.$router.push("/login");
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["getSetting", "checkLogin"]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    doCheckLogin() {
      if (this.$route.path !== '/login') {
        this.checkLogin();
      }
    }
  },
  async mounted() {
    await this.getSetting();
    setTimeout(() => {
      this.doCheckLogin();
    }, 500);
  }
};