import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {
      isLock: false
    };
  },
  methods: {
    onLock() {
      this.isLock = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        this.$router.push("/quest");
      }, 2000);
    }
  }
};