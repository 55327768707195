import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  data() {
    return {
      username: "",
      password: "",
      isFlag: true
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "isSign"])
  },
  watch: {
    isSign: {
      handler(newBool, oldBool) {
        if (newBool) {
          this.$router.push("/");
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...walletVuex.mapActions(["getSetting", "doLogin"]),
    async loginFn() {
      if (!this.username) {
        this.$toasted.error("Please enter account");
        return;
      }
      if (!this.password) {
        this.$toasted.error("Please enter passwor");
        return;
      }
      if (!this.isFlag) return;
      this.isFlag = false;
      await this.doLogin([this.username, this.password]);
      this.isFlag = true;
    }
  }
};