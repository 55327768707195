var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "login",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v(" Manage ")]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Account")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.username,
      expression: "username"
    }],
    domProps: {
      "value": _vm.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.username = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Password")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    attrs: {
      "type": "password"
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.password = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.loginFn
    }
  }, [_vm._v("Login")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };