import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      list: {},
      popTaskShow: false,
      modifyData: {
        "icon": ""
      },
      isLoading: false,
      selectTab: 0,
      taskId: 0
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let rs = await this.$http.post("/subtask/list", {
        status: this.selectTab == 0 ? 1 : 0,
        "taskId": this.taskId
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data;
      }
    },
    async onCreate() {
      this.modifyData = {
        "icon": "",
        'status': "1",
        "type": 'telegram',
        'verifiable': "0",
        "limit_count": "0",
        "task_id": this.taskId
      };
      this.popTaskShow = true;
    },
    async onModify(item) {
      this.modifyData = item;
      this.popTaskShow = true;
    },
    cutStr(str) {
      if (str) return str.replace("T", " ").replace(".000Z", "");
    },
    onRefresh() {
      this.getData();
    }
  },
  mounted() {
    this.taskId = this.$route.query.id || 0;
    if (!this.taskId) {
      this.$router.back();
    }
    this.getData();
  }
};