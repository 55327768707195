import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      list: {},
      popRefShow: false,
      modifyData: {},
      isLoading: false
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let rs = await this.$http.post("/u/referral/list", {});
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data;
      }
    },
    async onCreate() {
      // let rs = await this.$http.post("/referral/modify",{});
      // if(rs.code == 0){
      //   this.list = rs.data
      // }
      this.modifyData = {
        username: ""
      };
      this.popRefShow = true;
    },
    async onRemove(item) {
      let rs = await this.$http.post("/u/referral/modify", {
        ref_code: item.ref_code,
        is_viewstat: 0
      });
      if (rs.code == 0) {
        this.list = rs.data;
      }
    },
    onModify(item) {
      this.modifyData = Object.assign({}, item);
      this.modifyData.username = item.view_remark;
      this.popRefShow = true;
    },
    onRefresh() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};