import axios from "axios";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
import cookie from "js-cookie";
export default {
  name: "popTask",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      formData: {}
    };
  },
  watch: {
    "$parent.popRefShow": {
      async handler(newVal, oldVal) {
        if (newVal) {
          this.formData = Object.assign({}, this.$parent.modifyData);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async onConfirm() {
      this.formData.is_viewstat = 1;
      let rs = await this.$http.post("/u/referral/modify", this.formData);
      if (rs.code == 0) {
        this.$parent.list = rs.data;
      }
      this.$parent.popRefShow = false;
    }
  }
};