var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "myDatePicker",
    style: {
      '--width': _vm.width,
      '--height': _vm.height
    }
  }, [_c('date-picker', {
    attrs: {
      "editable": false,
      "value-type": "timestamp",
      "format": "YYYY/MM/DD",
      "value": _vm.value,
      "range": ""
    },
    on: {
      "input": _vm.onInput
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };