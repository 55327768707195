import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting', "tonConnect"])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      isLoading: false,
      isChecking: false,
      list: {},
      popDepositShow: false,
      itemData: {}
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let rs = await this.$http.post("/assets/account/info", {});
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data.assets;
      }
    },
    cutStr(str) {
      if (str) return str.replace("T", " ").replace(".000Z", "");
    },
    onRefresh() {
      this.getData();
    },
    async onCheck() {
      this.isChecking = true;
      let rs = await this.$http.post("/assets/account/check", {});
      this.isChecking = false;
      if (rs.code == 0) {
        this.list = rs.data.assets;
      }
    },
    onDeposit(item) {
      this.itemData = item;
      this.popDepositShow = true;
    }
  },
  mounted() {
    console.log("this.tonWallett", this.tonWallet);
    this.getData();
  }
};