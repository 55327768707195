import axios from "axios";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
import cookie from "js-cookie";
export default {
  name: "popTask",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      modifyData: {},
      isUploading: false
    };
  },
  watch: {
    "$parent.popMineTaskShow": {
      async handler(newVal, oldVal) {
        if (newVal) {
          this.modifyData = Object.assign({}, this.$parent.modifyData);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onFileChange(e) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      if (!e.target.files[0].type.startsWith('image/')) {
        this.$refs.fileInput.value = '';
        this.$toasted.error('请选择图片');
        return false;
      }
      if (this.isUploading) return;
      this.isUploading = true;
      let token = cookie.get('token') || '';
      formData.append('_token', token);
      // 使用axios上传文件
      axios.post(`${loadConfig.http_host}/upload?_token=${token}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        let rs = response.data;
        if (rs.code == 0) {
          this.modifyData.icon = rs.data.url;
          this.$set(this.modifyData, "icon", rs.data.url);
        } else {
          this.$toasted.error(rs.message);
        }
        this.isUploading = false;
        this.$refs.fileInput.value = '';
      }).catch(error => {
        console.error(error);
        this.isUploading = false;
        this.$refs.fileInput.value = '';
      });
    },
    async onConfirm() {
      await this.$http.post("/mine/task/modify", this.modifyData);
      this.$parent.getData();
      this.$parent.popMineTaskShow = false;
    }
  }
};